* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
figure {
  margin: 0px;
  padding: 0px;
}

.headermain {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  text-align: center;
  pointer-events: all;
  transition: transform .4s ease 0s, opacity .4s ease 0s, visibility .4s ease 0s;
  will-change: transform, opacity, visibility, top;
}

.header__logo {
  display: block;
  width: 100%;
  max-width: 120px;
  min-width: 120px;
  height: 138px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 5.5px auto;
}

.header__logo img {
  width: 100%;
  height: 100%;
}

.hambargericon {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  min-width: 60px;
  min-height: 60px;
  padding: 18px;
  background-color: rgba(0, 0, 0, .4);
  border: none;
  outline: 0;
  color: #fff;
  transition: all .25s ease 0s;
  z-index: 9999;
  cursor: pointer;
}

.hambargericonlinediv {
  width: 100%;
  height: 24px;
  position: relative;
  z-index: 9999;
}

.hambargericonlinediv::before {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  background-color: currentColor;
  content: "";
  transition: top .2s ease .2s, transform .2s ease 0s;
  top: 3px;
  z-index: 9999;

}

.hambargericonlinediv .line1 {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  background-color: currentColor;
  top: calc(50% - 1px);
  transition: transform 0s ease .2s;
  z-index: 9999;
}

.hambargericonlinediv::after {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  background-color: currentColor;
  top: calc(100% - 5px);
  content: "";
  transition: top .2s ease .2s, transform .2s ease 0s;
  z-index: 9999;
}

.pagecontentdivmain {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.pagecontentdivmain:after {
  display: none;
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, .5);
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s ease 0s, visibility .4s ease 0s;
}

/* sidebar */

.sidebardiv {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  width: 312px;
  transform: translate3d(100%, 0, 0);
  background-color: #000;
  line-height: 20px;
  text-transform: uppercase;
  transition: transform .5s cubic-bezier(.4, 0, .2, 1) 0s;
  will-change: transform;
}

.sidebardiv.actv {
  transform: none;
}

.sub-menu-back-button {
  position: absolute;
  top: 18px;
  left: 30px;
  line-height: 24px;
  display: none;
  color: rgba(255, 255, 255, .5);
  cursor: pointer;
  transition: .3s ease;
}

.menu__main {
  width: 100%;
  height: calc(100% - 187px);
  overflow: hidden;
  position: relative;
  overflow-y: auto !important;
}

.menu__main .menu__list {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: .4s ease-out;
}

.menu__main .menu__list .menu__link {
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 16px;
  color: rgba(255, 255, 255, .5);
  text-decoration: none;
}

.menu__link {
  text-align: center;

}

.lnkmntetstxpp {
  color: rgba(255, 255, 255, .5);
  text-decoration: none;
  width: auto;
  height: 20px;
  padding: 0 15px;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  display: block;
  transition: color .25s ease 0s, border .25s ease 0s;
}

.lnkmntetstxpp:hover {
  color: #fff;
  text-decoration: none;
  width: auto;
  height: 20px;
  padding: 0 15px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  display: block;
  transition: color .25s ease 0s, border .25s ease 0s;
}

.menulogosidebavrimg {
  width: 100%;
  max-width: 70px;
  min-width: 70px;
  height: 40px;
  margin: 0 auto 45px;
}

.menulogosidebavrimg img {
  width: 100%;
  height: 100%;
}

.menu__languages,
.menu__social {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #191919;
}

.menu__languages .menu__linklistcls {
  width: 50%;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  border-right: 1px solid #191919;
}

.menu__listdivftr {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu__linkvshttxtpp {
  color: rgba(255, 255, 255, .5);
  font-size: 14px;
  text-decoration: none;
}

.footersectionmain {
  right: 60px;
  margin-bottom: 15px;
  pointer-events: all;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  transition: transform .4s ease 0s, opacity .4s ease 0s, visibility .4s ease 0s;
  width: 100%;
  will-change: transform, opacity, visibility, top;
  /* z-index: 2001; */
  width: 100%;
  max-width: 10%;
}

.custfottercontaiuner {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
}

.footermaindivflx {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
  height: 100%;
}

.socials-container {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.socials-container a {
  background-color: white;
  padding: 1em;
  border-radius: 50%;
  height: 50px;
  width: 100%;
  max-width: 50px;
  min-width: 50px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: grid;
  place-items: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.socials-container a svg {
  height: 20px;
}

.socials-container a::before {
  content: attr(data-social);
  position: absolute;
  background-color: #ffffff;
  color: black;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 100px;
  box-shadow: 0 0px 5px 0px #ccc;
  transform: translateY(-30px) rotate(25deg);
  opacity: 0;
  transition: 200ms cubic-bezier(.42, 0, .44, 1.68);
  font-size: 15px;
  font-weight: 600;
}

.socials-container a:hover {
  background-color: #000000;
  fill: white;
}

.socials-container a::after {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #fff;
  transform: translateY(0) rotate(25deg);
  opacity: 0;
  transition: 200ms cubic-bezier(.42, 0, .44, 1.68);
}

.socials-container a:hover::before {
  transform: translateY(-65px) rotate(0);
  opacity: 1;
}

.socials-container a:hover::after {
  transform: translateY(-42px) rotate(0);
  opacity: 1;
}

@media(max-width:767px) {
  .socials-container a {
    background-color: white;
    padding: 1em;
    border-radius: 50%;
    height: 40px;
    width: 100%;
    max-width: 40px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.socials-container{
  flex-direction: row;
}
.footersectionmain {
  width: 100%;
  height: 60px;
  position: absolute;
  top: 48px;
  left: 50%;
  margin-bottom: 20px;
  transform: translateX(-50%);
}

  .socials-container a svg {
    height: 30px;
    width: 100%;
    max-width: 20px;
    min-width: 20px;
  }

  .footermaindivflx {
    display: flex;
    justify-content: center;
  }
  .socials-container a:hover::before {
    transform: translateY(-52px) rotate(0);
    opacity: 1;
}
.socials-container a:hover::after {
  transform: translateY(-32px) rotate(0);
  opacity: 1;
}

}

@media(max-width:420px){
  .socials-container a::before {
    content: attr(data-social);
    position: absolute;
    background-color: #ffffff;
    color: black;
    text-decoration: none;
    padding: 0.3em 0.5em;
    border-radius: 100px;
    box-shadow: 0 0px 5px 0px #ccc;
    transform: translateY(-30px) rotate(25deg);
    opacity: 0;
    transition: 200ms cubic-bezier(.42, 0, .44, 1.68);
    font-size: 11px;
    font-weight: 600;
}
.socials-container a:hover::before {
  transform: translateY(-43px) rotate(0);
  opacity: 1;
}
.socials-container a:hover::after {
  transform: translateY(-28px) rotate(0);
  opacity: 1;
}

  .footersectionmain {
    width: 100%;
    height: 60px;
    position: absolute;
    top: 50px;
    left: 50%;
    margin-bottom: 20px;
    transform: translateX(-50%);
  }
}

