/* @media (min-width: 1025px) {
    body.page-template-car .blocks-pagination {
   
    }
}
@media (min-width: 1025px) {
    body.init .blocks-pagination {
        transform: translate(35px, -50%);
    }
} */
/* body.init .blocks-pagination {
    transform: translate(0, -50%);
    opacity: 1;
    visibility: visible;
    will-change: auto;
} */
/* @media (min-width: 1025px) {
    .blocks-pagination {
        display: none;
        padding-bottom: 60px;
        transform: translate(-100%, -50%);
        border-left: solid 1px rgba(255, 255, 255, .5);
    }
} */
.blocks-pagination {
    position: fixed;
    top: 50%;
    left: 0;
    opacity: 1;
    /* transform: translate(35px, -50%); */
    visibility: visible;
    will-change: auto;
    transition: transform .4s ease 0s, opacity .4s ease 0s, visibility .4s ease 0s;
    will-change: transform, opacity, visibility;
    z-index: 2;
    display: block;
    border-left: solid 1px rgba(255, 255, 255, .5);
    padding-bottom: 60px;
    animation: animatenew 0.5s forwards;
}
@keyframes animatenew{
    

    0%{
        transform: translate(-100%,-50%) ;
        opacity: 0;
    }
    50%{
        transform: translate(-100%,-50%) ;
        opacity: 0;
    }
  
    100%{
        transform: translate(35px, -50%); ;
        opacity: 1;
    }
}
.blocks-pagination__item {
    width: 55px;
    height: 60px;
    padding: 30px 0 0;
    overflow: hidden;
    position: relative;
    margin-left: -12px;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px rgba(255, 255, 255, .5);
    border-radius: 0;
    font-size: 14px;
    color: rgba(255, 255, 255, .5);
    cursor: pointer;
    transition: width .25s ease 0s, border .25s ease 0s, color .25s ease 0s;
}


.blocks-pagination__item.current {
    border-color: #fff;
    color: #fff;
    background-color: transparent;
    width: 180px;
}

.blocks-pagination__item:before {
    width: 55px;
    padding: 5px 12px 5px 25px;
    content: "0" attr(data-index);
    left: 0;
    font-size: 14px;
    display: inline-block;
    box-sizing: border-box;
    height: 30px;
    position: absolute;
    bottom: 0;
    line-height: 20px;
    text-align: center;
}

.blocks-pagination__item:after {
    width: 125px;
    padding: 5px;
    content: attr(data-label);
    left: 55px;
    transform: translateY(100%);
    font-size: 10px;
    text-transform: uppercase;
    text-align: right;
    opacity: 0;
    visibility: hidden;
    transition: transform .25s ease 0s, opacity .25s ease 0s, visibility .25s ease 0s;
    transform: none;
    opacity: 1;
    visibility: visible;
    transition-duration: .5s;
    display: inline-block;
    box-sizing: border-box;
    height: 30px;
    position: absolute;
    bottom: 0;
    line-height: 20px;
}

.blocks-pagination__item.current,
.blocks-pagination__item:hover {
    width: 180px;
    border-color: #fff;
    color: #fff;
}

.block {
    width: 100%;
    height: 100%;
    padding: 60px 0 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(100%);
    background-color: #000;
    will-change: transform, opacity, visibility;
    transition: transform 1s cubic-bezier(.4, 0, .2, 1) 0s;
}

.block[data-child-index="1"],
.block[data-index="1"] {
    transform: none !important;
}

.block.slide-in {
    transform: none ;
    
}

.block .block__background {
    display: block;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: transform .7s cubic-bezier(.55, .09, .68, .53) 0s, opacity .7s cubic-bezier(.55, .09, .68, .53) 0s, visibility .7s cubic-bezier(.55, .09, .68, .53) 0s;
    will-change: transform, opacity, visibility;
    max-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    font-family: "object-fit: cover";
}

.current>.block__background {
    transition-timing-function: cubic-bezier(.46, .03, .52, .96);
    transition-delay: .35s;
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
    visibility: visible;
}

.blockcontentdiscover {
    padding: 0px 290px;
}
.videoicondivleft{
    margin-left: auto;
}
.videoicondivleft i{
    font-size: 18px;
    color: #fff;
}


.blcktitleheadtxtp {
    font-size: 16px;
    letter-spacing: .1em;
    font-weight: 400;
    line-height: 25px;
    text-transform: uppercase;
    color: #fff;
}

.blcktitleparatxtp {
    font-size: 16px;
    letter-spacing: .05em;
    font-weight: 300;
    line-height: 20px;
    display: block;
    margin: 10px 0px;
    /* margin-bottom: 25px; */
    color: #fff;
    text-align: justify;
}
.accordion-body {
    /* padding: 1rem 1.25rem; */
    padding: 0px 20px !important;
}
.accordion-item {
    background-color: transparent !important;
    /* border: 1px solid #fff !important; */
    margin-bottom: 25px;
    border-bottom-left-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
}
.accordion-header {
    margin-bottom: 0;
    /* border-bottom: 2px solid #ccc; */
}
.accordion-button {
    align-items: baseline;
    padding: 0px 21px !important;
}
.accordion-button {
    border: 0px solid #fff !important;
    background-color: transparent !important; 
}
.accordion-button:not(.collapsed) {
    color: #fff !important;
    background-color: transparent !important;
    /* box-shadow: 0px 0px 5px 0px #ccc; */
    border: 0px solid #fff !important;
}
.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url(../../Images/plus-solid.svg);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
    position: absolute ;
    top: 2px;
    left: -16px;
}
.accordion-button:not(.collapsed)::after {
    background-image: url(../../Images/minus-solid.svg);
    transform: rotate(0deg) !important;
}
.accordion-button:focus {
    z-index: 3;
    border-color: #FFf;
    outline: 0;
    box-shadow: none;
    /* box-shadow: 0px 0px 5px 0px #ccc; */
}

.blockcontentdivflx{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;

}
.blockcontentdivcntn{
    padding: 20px;
}
.textforresponsiverrrx{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    opacity:0;
    transition: 0.5s all ease-in-out;
}
/* .blockcontentdivcntn{
    width: 100%;
    max-width: 24%;
    min-width: 24%;
} */

@media(max-width:1199px){
    .blcktitleheadtxtp {
        font-size: 15px;
        letter-spacing: .1em;
        font-weight: 400;
        line-height: 21px;
        text-transform: uppercase;
        color: #fff;
    }
    .accordion-button::after {
        flex-shrink: 0;
        width: 1.5rem;
        height: 1.5rem;
    }
    .blockcontentdiscover {
        padding: 0px 200px 0px 250px;
    }
    .blcktitleparatxtp {
        font-size: 14px;
        line-height: 18px;

    }
}
@media (max-width: 991px) {
    .blcktitleheadtxtp {
        font-size: 12px;
        letter-spacing: .1em;
        font-weight: 400;
        line-height: 16px;
    }
    .blcktitleparatxtp {
        font-size: 13px;
        line-height: 15px;
    }
    .blockcontentdiscover {
        padding: 0px 110px 0px 200px;
    }
    .blocks-pagination__item.current, .blocks-pagination__item:hover {
        width: 130px;
        border-color: #fff;
        color: #fff;
    }
    /* .blocks-pagination__item:after {
        left: 7px;
     
    } */
    .blocks-pagination__item:after {
       
        text-align: left;
        padding: 5px 0px;
        font-size: 9px;
       
    }
    .blocks-pagination__item:before {
        font-size: 12px;
    }
}
@media(max-width:767px){
    
    .accordion-item {
        margin-bottom: 15px;

    }
    .blcktitleparatxtp {
        font-size: 12px;
        line-height: 14px;
    }
    .blcktitleheadtxtp {
        font-size: 11px;
        letter-spacing: .1em;
        font-weight: 400;
        line-height: 15px;
    }
    .blockcontentdiscover {
        padding: 0px 60px 0px 200px;
    }
    
    
}
@media(max-width:576px){
    .blocks-pagination{
       display: none;
    }
    .blockcontentdiscover {
        padding: 0px 30px 0px 30px;
    }
    .textforresponsiverrrx{
        opacity: 1;
        transition: 0.5s all ease-in-out;
    }
}
@media (max-width: 480px) {
    .blockcontentdiscover {
        padding: 0px 15px 0px 15px;
    }
    .accordion-button::after {
        flex-shrink: 0;
        width: 13px;
        height: 14px;
        background-size: auto;
        top: 5px;
        left: -6px;
    }
    .accordion-button {
        align-items: baseline;
        padding: 0px 14px !important;
    }
    
}
