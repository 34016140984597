.privacycontauiner {
    width: 100%;
    max-width: 70%;
    margin: 0px auto;
}

.privacyplociyheadtxtpp {
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px;
    letter-spacing: 1px;
}

.privacyplocysection {
    padding: 40px 0px;
}

.privacypolicypatrapara {
    width: 100%;
    font-size: 17px;
    line-height: 24px;
    color: #000;
    padding-top: 70px;
}

.privacypolicypatrasubpara {
    width: 100%;
    font-size: 17px;
    line-height: 24px;
    color: #000;
    padding-top: 10px;
}

.privacypolicylistttxpul {
    padding-top: 20px;
    list-style-type: none;
    list-style-type: decimal;
}

.privacypolicylistttxplitxtppbld {
    font-size: 18px;
    color: #000;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 5px;
}

.privacypolicylistttxpli {
    margin-bottom: 15px;
    padding-inline-start: 12px;
}

.privacypolicylistttxplittx {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 22px;
}

.infofattstsppop{
    list-style-type: lower-roman;
}
.infofattstsppopli {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 22px;
    padding-inline-start: 10px;
    margin-bottom: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

@media(max-width:767px){
    .privacyplocysection {
        padding: 6px 0px;
    }
    .privacyplociyheadtxtpp {
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
        letter-spacing: 1px;
    }
    .privacypolicypatrapara {
        width: 100%;
        font-size: 17px;
        line-height: 24px;
        color: #000;
        padding-top: 45px;
    }
}