.hero_content {
    width: 100%;
    height: 100%;
    padding: 60px 0 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    will-change: transform, opacity, visibility;
    transition: transform 9s cubic-bezier(.4, 0, .2, 1) 0s;
    display: none;
}

.hero_content.cuurentchm {
    display: block;
    transition-timing-function: cubic-bezier(.46, .03, .52, .96);
    transition-delay: .35s;
    width: 100%;
}

.hero_content.cuurentchm video{
    width: 100%;
    height: 100%;
}

.cuurentchm>.block__background {
    transition-timing-function: cubic-bezier(.46, .03, .52, .96);
    transition-delay: .35s; 
    opacity: 1;
    visibility: visible;
    width: 100%;
} 

.block__background {
    display: block;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: transform .7s cubic-bezier(.55, .09, .68, .53) 0s, opacity .7s cubic-bezier(.55, .09, .68, .53) 0s, visibility .7s cubic-bezier(.55, .09, .68, .53) 0s;
    will-change: transform, opacity, visibility;
    max-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}


.bg-pattern_cuurent::before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-image: url(../../Images/bg-pattern.png);
    background-size: 3px 3px;
    background-position: 0 0;
    background-repeat: repeat;
    background-color: #0000008a;
}
.gggaaaadivflx{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
    /* width: 100%; */
    /* max-width: 30%; */
    margin: auto;
    margin-top: 57px;
}
.gggdibvimng{
    width: 100%;
    max-width: 183px;
    min-width: 183px;
    height: 74px;
}
.gggdibvimng img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.block__content {
    width: 100%;
    padding: 0 7%;
    z-index: 1;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.block__logo.hp-logo {
    max-width: 255px;
    min-width: 255px;
    width: 100%;
    margin-bottom: 35px;
}

.block__title-h1 {
    font-size: 36px;
    letter-spacing: .1em;
    font-weight: 500;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 32px;
}

.block__title-h2 {
    font-size: 13px;
    letter-spacing: .05em;
    font-weight: 100;
    line-height: 20px;
    display: block;
    margin-bottom: 40px;
}

.diacpressrealsttxpp {
    color: #fff;
    font-weight: 300;
    text-decoration: none;
}
.diacpressrealsttxpp:hover{
    text-decoration: none;
    color: #fff;
}

.scroll-invitation {
    padding-bottom: 20px;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    text-align: center;
}

.scroll-invitation__label--mobile {
    padding-bottom: 30px;
}

.scroll-invitation__label--desktop {
    display: inline-block;
    padding-top: 50px;
    color: rgba(255, 255, 255, .5);
    font-size: 14px;
}

.scroll-invitation__svg {
    display: block;
    fill: #fff;
    margin: 0 auto 15px auto;
}

.scroll-invitation__label {
    display: inline-block;
    position: relative;
    text-transform: uppercase;
}

.scroll-invitation__svg-line {
    -webkit-animation: moveLineDown 1s ease-out;
    animation: moveLineDown 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.scroll-invitation__label--mobile {
    display: none;
}

@keyframes moveLineDown {
    0% {
        transform: translateY(-5px);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translateY(15px);
        opacity: 0;
    }
}

.block__content {
    width: 100%;
    padding: 0 7%;
    z-index: 1;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.block__list {
    font-size: 0;
    text-align: center;
}

.block__list li {
    display: inline-block;
    min-width: 240px;
    border-bottom: 0;
    border-right: 1px solid #151515;
    text-align: center;
}

.block__list li .block__list-button {
    width: 100%;
    color: #fff;
    background: 0 0;
    border: 0;
    display: block;
    outline: 0;
    cursor: pointer;
    font-size: 20px;
    padding: 25px 60px;
}

.block__footer {
    position: absolute;
    bottom: 5px;
    left: 50%;
    z-index: 2;
    text-align: center;
    transform: translate(-50%, -50%);
    bottom: 25px;
}

.block__quote {
    font-style: italic;
    color: #fff;
    text-align: center;
    display: block;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 23px;
    text-transform: initial;
    margin-bottom: 30px;
}
.PrivacyPolicymaincontb {
    padding-top: 40px;

}

.PrivacyPolicymaincontbtxt {
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    text-decoration: none;
}
.PrivacyPolicymaincontbtxt:hover{
    color: #fff;
    text-decoration: none;
}
@media(max-width:480px){
    .gggdibvimng {
        width: 100%;
        max-width: 143px;
        min-width: 143px;
        height: 74px;
    }
}

