.contactdivmain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 90%;
    min-width: 90%;
    background-color: transparent;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 20px;
    margin: auto;
    border-radius: 10px;
}

.contactdivmainflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    column-gap: 20px;
}

.contactdivmainleft {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
    position: relative;
}

.contactdivmainleft::after {
    position: absolute;
    content: "";
    isolation: isolate;
    width: 2px;
    height: 100%;
    background-color: #fff;
    top: 0;
    right: -40px;
}

.contactdivmainright {
    width: 100%;
    max-width: 54%;
    min-width: 54%;
}

.conatctstxtspppp {
    font-size: 20px;
    color: #fff;
    text-align: center;
}

.contxtggogleomaoo {
    width: 100%;
    height: 195px;
}

.contxtggogleomaoo iframe {
    width: 100%;
    height: 100%;
}

.contxtggogleomaoobg {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}

/* .sliderbannerbgdivrightbg{
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
    padding: 12px 14px;
} */

/* .lerantsxtpp {
    position: relative;
    padding: 20px 40px;
    text-align: center;
    background-color: #f0f0f0;
    transition: color 0.3s ease-in-out;
    font:600 20px/30px var(--section-heading-text) ;
    color: var(--bgcolor3);
} */
.lerantsxtpp::before {
    height: 0;
    left: 0;
    top: 0;
    width: 100%;
    transition-delay: 0s;
}

.lerantsxtpp::after {
    height: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transition-delay: 0.6s;
}

.lerantsxtpp::before,
.lerantsxtpp::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    background-color: #ffffff;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
}

.lerantsxtpp:hover::before {
    width: 100%;
    transition-delay: 0s;
}

.lerantsxtpp:hover::after {
    width: 100%;
    transition-delay: 0.6s;
}

.lerantsxtpp:hover {
    color: #000;
}

.lerantsxtpp {
    padding: 11px 40px;
    text-align: center;
    background-color: #0000007d;
    transition: color 0.3s ease-in-out;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    position: relative;
}

.lerantsxtpp::before {
    height: 0;
    left: 0;
    top: 0;
    width: 100%;
    transition-delay: 0s;
}

.lerantsxtpp::after {
    height: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transition-delay: 0.6s;
}

.lerantsxtpp::before,
.lerantsxtpp::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    background-color: #ffffff;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
}

.lerantsxtpp:hover::before {
    width: 100%;
    transition-delay: 0s;
}

.lerantsxtpp:hover::after {
    width: 100%;
    transition-delay: 0.6s;
}

.lerantsxtpp:hover {
    color: #ffffff;
}

.contactinfodivdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 6px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    min-width: fit-content;
}

.contactinfodivdileft {
    width: 100%;
    max-width: 20%;
    margin: auto;
    display: flex;
    justify-content: center;

}

.contactinfodivdiright {
    width: 100%;
    max-width: 80%;
    min-width: fit-content;
}

.contactinfodivdileftmain {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #000;
}

.contattxtrtsttpp {
    font-size: 18px;
    color: #000000;
    text-align: left;
    font-weight: 600;
    line-height: 22px;
}
.sliderbannerbgdivrightbg{
    margin-bottom: 20px;
}
.contttcfrmminamettxt {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    list-style: 24px;
    text-align: left;
}
.contttcfrmminamettxtinptty{
    margin-bottom: 15px;
}

.contttcfrmminamettxtinptty input {
    width: 100%;
    border: none;
    background-color: transparent;
    padding: 4px 20px 4px 0px;
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid #ccc;
    
}

.contttcfrmminamettxtinptty input:focus{
    border-bottom: 1px solid #ccc;
    outline: 0;
}
.contttcfrmminamettxtinptty textarea{
    width: 100%;
    border: none;
    background-color: transparent;
    padding: 4px 20px 4px 0px;
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid #ccc; 
    resize: none;
}
.contttcfrmminamettxtinptty textarea:focus{
    border-bottom: 1px solid #ccc;
    outline: 0;
}

@media(max-width:1199px){
    .contactinfodivdivflx {
        
        padding: 6px 6px;
      
    }
    
    .contactdivmain {
    
        width: 100%;
        max-width: 90%;
        min-width: 90%;
   
    }
    .contactinfodivdileftmain {
        width: 100%;
        max-width: 40px;
        min-width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #000;
    }
    .contactdivmainleft::after {
        right: -30px;
    }

}

@media(max-width:991px){
    .contactdivmainflex {
        flex-wrap: wrap;
        width: 100%;
        column-gap: 20px;
        height: 500px;
        overflow-y: auto;
    }
    .contactdivmainleft {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        position: relative;
        border-bottom: 2px solid #fff;
        margin: 0px 0px 15px 0px;
        padding: 15px 0px;
    }
    .contactdivmainleft::after {
        right: -30px;
        display: none;
    }
    .contactdivmainright {
        width: 100%;
        max-width: 100%;
        min-width: 100%
    }
    .contactdivmain {
        width: 100%;
        max-width: 80%;
        min-width: 80%;
    }
}
@media (max-width: 767px) {
    .contactdivmainflex {
        flex-wrap: wrap;
        width: 100%;
        column-gap: 20px;
        height: 369px;
        overflow-y: auto;
    }
    .contactdivmain {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin-top: 80px;
    }
}
@media (max-width: 420px) {
    .contactdivmain {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin-top: 48px;
    }
    .contactdivmainflex {
        flex-wrap: wrap;
        width: 100%;
        column-gap: 20px;
        height: 394px;
        overflow-y: auto;
    }
}


